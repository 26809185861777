export default (): void => {
  const social = document.querySelector('*[data-social]') as HTMLDivElement

  if (!social) return

  const round = social.querySelector('*[data-social-round]') as HTMLDivElement
  const links = social.querySelectorAll('*[data-social-link]') as NodeListOf<HTMLAnchorElement>
  const btn = social.querySelector('*[data-social-button]') as HTMLButtonElement

  const doubleTap = (): void => {
    round.dataset.socialRound = round.dataset.socialRound === 'show' ? '' : 'show'
  }

  for (let i = 0; i < links.length; i++) {
    const network = links[i] as HTMLAnchorElement

    if ((document.documentElement as HTMLHtmlElement).clientWidth < 609.98) {
      network.style.top = (38 + 35 * Math.sin(-0.5 * Math.PI - 2 * (1 / links.length) * i * Math.PI)).toFixed(4) + '%'
      network.style.left = (38 - 35 * Math.cos(-0.5 * Math.PI - 2 * (1 / links.length) * i * Math.PI)).toFixed(4) + '%'
    } else {
      network.style.top = (42 + 38 * Math.sin(-0.5 * Math.PI - 2 * (1 / links.length) * i * Math.PI)).toFixed(4) + '%'
      network.style.left = (42 - 38 * Math.cos(-0.5 * Math.PI - 2 * (1 / links.length) * i * Math.PI)).toFixed(4) + '%'
    }
  }

  btn.addEventListener('click', doubleTap as EventListener)
}
